import React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Button, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles"

import "./MUIstyling.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

import { host } from "../../config";
import RowDetailsDialog from "./claimsEobDataComponent";

export type DataType = {
  uid: string; // Unique identifier
  Ind: string;
  Last_Name: string;
  First_Name: string;
  Status: string;
  Claim_Number: number;
  Paid_Date: string;
  Provider_Name: string;
  Tax_ID: string;
  Provider_Addr1: string;
  Provider_City: string;
  Provider_State: string;
  Provider_Zip: string;
  Service_From: string;
  Service_Thru: string;
  Billed_Amount: number;
  Paid_Amount: number;
  Not_Covered_Amount: number;
  Discount_Amount: number;
  Co_Pay_Amount: number;
  Applied_to_Ded: number;
  Relationship?: string; // Add this field if it's not already in your data
};

export type EobDataType = {
  Claim_Number: string;
  Provider_Name: string;
  Tax_ID: string;
  Provider_Addr1: string;
  Provider_City: string;
  Provider_State: string;
  Provider_Zip: string;
  Service_From: string;
  Service_Thru: string;
  Billed_Amount: string;
  Paid_Amount: string;
  Not_Covered_Amount: string;
  Discount_Amount: string;
  Co_Pay_Amount: string;
  Applied_to_Ded: string;
  Deductible_Remaining: string;
  Copay_Remaining: string;
  Visits_Remaining: string;
  Additional_Notes: string;
};

function determinePrimaryOrDependent(row: DataType): string {
  // Implement the logic to determine if it's "Primary" or "Dependent"
  return Number(row.Relationship) === 0 ? "Primary" : "Dependent"; // Example logic
}

function formatData(input: DataType): DataType {
  return {
    ...input,
    Relationship: determinePrimaryOrDependent(input), // Calculate the new field
  };
}

declare module "@mui/material/styles" {
  interface Components {
    // This should extend the existing MUI theme types with the new `MuiDataGrid` component.
    MuiDataGrid?: {
      styleOverrides?: {
        // Your style overrides here
        columnHeaders: {
          "& .MuiDataGrid-sortIcon": {
            color: "white"; // Default color
            "&:hover": {
              color: "white"; // Change to white on hover
            };
          };
        };
      };
    };
  }
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          "& .MuiDataGrid-sortIcon": {
            color: "white",
            "&:hover": {
              color: "white",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "white", // For other icon buttons
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            color: "white",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectIcon: {
          color: "black",
        },
        select: {
          color: "black",
        },
        actions: {
          "& .MuiIconButton-root": {
            color: "black", // Ensures the pagination arrows are always black
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)", // Adds a subtle background on hover
              color: "black", // Keeps the icon black on hover
            },
          },
        },
      },
    },
  },
});

function removeDuplicateClaims(data: DataType[]): DataType[] {
  const seen = new Set<number>();
  return data.filter((item) => {
    const duplicate = seen.has(item.Claim_Number);
    seen.add(item.Claim_Number);
    return !duplicate;
  });
}

export const ClaimsDataGrid = (props: { idToken: string; data: DataType[] }) => {
  const filteredRows = removeDuplicateClaims(props.data.map((entry) => formatData(entry)));

  const [open, setOpen] = React.useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<DataType | null>(null);
  const [eobData, setEobData] = React.useState<EobDataType[]>([]);

  const [isLoading, setIsLoading] = React.useState(false);

  const tk = props.idToken;

  const handleOpen = async (row: DataType) => {
    setSelectedRow(row);
    await handleGetEob(row);
    setOpen(true);
  };

  const handleGetEob = async (row: DataType) => {
    let holder: { [key: string]: any } = {
      claimNo: row.Claim_Number,
    };

    setIsLoading(true);

    let params = new URLSearchParams(holder);

    fetch(`${host}/api/claims/claimEob?${params}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then((response: Response) => {
        if (response.status != 200) {
          response.text().then((text) => {
            console.log(text);
            alert(`An error has occured when fetching the EOB:\n${text}`);
          });
          return;
        } else {
          response.json().then((res) => {
            setIsLoading(false);
            setSelectedRow(res);
            setEobData(res.data);
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
        alert("An error has occured when searching for claims");
      })
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const headCells: GridColDef[] = [
    {
      field: "More Info",
      headerName: "More Info",
      width: 140,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <strong>{params.value}</strong>
          <Button
            variant="contained"
            size="small"
            style={{ marginLeft: "8px", backgroundColor: "#004e9d" }}
            onClick={() => handleOpen(params.row)}
          >
            Member EOB
          </Button>
        </Box>
      ),
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Relationship",
      headerName: "Relationship",
      width: 130,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Last_Name",
      headerName: "Last Name",
      width: 140,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "First_Name",
      headerName: "First Name",
      width: 140,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    { field: "Status", headerName: "Status", width: 130, editable: false, headerClassName: "super-app-theme--header" },
    {
      field: "Claim_Number",
      headerName: "Claim Number",
      width: 150,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Paid_Amount",
      headerName: "Paid Amount",
      width: 150,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Paid_Date",
      headerName: "Paid Date",
      width: 150,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Provider_Name",
      headerName: "Provider Name",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    { field: "Tax_ID", headerName: "Tax ID", width: 150, editable: false, headerClassName: "super-app-theme--header" },
    {
      field: "Provider_Addr1",
      headerName: "Provider Address",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Provider_City",
      headerName: "City",
      width: 150,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Provider_State",
      headerName: "State",
      width: 100,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Provider_Zip",
      headerName: "ZIP",
      width: 120,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
  ];

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

function CustomToolBar () {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton sx={{color: "#004e9d"}}/>
        <GridToolbarExport sx={{color: "#004e9d"}}/>
        <HtmlTooltip  title={
          <React.Fragment>
            <Typography color="inherit">Claim Statuses</Typography>
            <b>{"Payable:"}</b> {" The claim has been fully processed, and all claim lines are eligible for payment."} <br/>
            <b>{"Denied:"}</b>{" The claim has been processed, and all claim lines are ineligible for payment."} <br/>
            <b>{"Partial Deny:"}</b> {" The claim has been processed, with some lines eligible for payment while others are not."} <br/>
            <b>{"Pending Review:"}</b> {" The claim has been adjudicated but is still under review."} <br/>
            <b>{"Pre-Adjudication:"}</b> {" The claim has been received but has not been adjudicated yet."}
          </React.Fragment>
        } placement="right" arrow>
        <Button sx={{color: "#004e9d"}} startIcon={<InfoIcon />}>Claim Statues</Button>
        </HtmlTooltip>
      </GridToolbarContainer>
    );
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: 650,
          width: "89%", // Updated to 100%
          backgroundColor: "#f8f8f8",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Enhanced shadow
          padding: "20px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "& .super-app-theme--header": {
            backgroundColor: "#004e9d",
            color: "#ffffff",
            padding: "10px 20px",
            boxShadow: "0 3px 6px rgba(0,0,0,0.2)", // Enhanced shadow
            fontWeight: "bold",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #e0e0e0",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            color: "#333",
          },
        }}
      >
        <DataGrid
          sx={{
            height: "auto", // Allows DataGrid to grow with content up to the Box height
            width: "100%", // Ensures DataGrid uses full width of the Box, considering padding
            "& .MuiDataGrid-virtualScroller": {
              marginTop: "0 !important", // Adjusts the header alignment issue
            },
          }}
          rows={filteredRows}
          columns={headCells}
          getRowId={(filteredRows) => filteredRows.uid}
          disableColumnSelector
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5]}
          slots={{ toolbar: CustomToolBar }}
          disableRowSelectionOnClick
        />
      </Box>

      <RowDetailsDialog open={open} onClose={handleClose} selectedRow={selectedRow} eobData={eobData} />
    </ThemeProvider>
  );
};

export default ClaimsDataGrid;
