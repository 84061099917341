import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import Papa from "papaparse";
import { getFirestore } from "firebase/firestore";
import { app, host } from "../../config";
import { v4 as uuidv4 } from "uuid";

import "./ClaimsLookupSystem.css";
import Tooltip from "@mui/material/Tooltip";
import { DataType } from "./claimsMainDataComponent";
import CircularProgress from "@mui/material/CircularProgress";
import ClaimsDataGrid from "./claimsMainDataComponent";

const auth = getAuth();
const db = getFirestore(app);

const ClaimsLookupSystem: React.FC = () => {
  const [groupNumber, setGroupNumber] = useState("");
  const [claim, setClaim] = useState("");
  const [assignedId, setAssignedId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [paidDateStart, setPaidDateStart] = useState(""); // New state for start of claim date range
  const [paidDateEnd, setPaidDateEnd] = useState(""); // New state for end of claim date range
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [apiData, setApiData] = useState<Array<DataType>>([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showApiData, setShowApiData] = useState(false);
  const [isVBA, setIsVBA] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tk, setTk] = useState("")

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended, role, selectedGroup } = authContext;
  const [isAdmin, setIsAdmin] = useState(() => role === "admin");

  const navigate = useNavigate();

  useEffect(() => {
    if (role == "broker" || role == "employer") setGroupNumber(selectedGroup);
  }, [selectedGroup]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      setTk(idToken);
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const handleGroupNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupNumber(e.target.value);
  };

  const handleClaimChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClaim(e.target.value);
  };

  const handleAssignedIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAssignedId(e.target.value);
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handlePaidDateStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaidDateStart(e.target.value);
  };

  const handlePaidDateEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaidDateEnd(e.target.value);
  };

  const handleDateOfBirthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateOfBirth(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (groupNumber === "") {
      alert("Please fill in the group number field before submitting!");
      return;
    }

    let holder: { [key: string]: any } = {
      groupNo: groupNumber,
      claimNo: claim,
      assignedId: assignedId,
      firstName: firstName,
      lastName: lastName,
      paidDateStart: paidDateStart, // Include start date of service
      paidDateEnd: paidDateEnd, // Include end date of service
      dateOfBirth: dateOfBirth,
    };

    setIsLoading(true);

    let params = new URLSearchParams(holder);
    params.append("pageSize", pageSize.toString());

    fetch(`${host}/api/claims/claims?${params}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then((response: Response) => {
        if (!response.ok) {
          response.text().then((text) => {
            console.log(text);
            alert(`An error has occured with your query:\n${text}`);
          });
          return;
        } else {
          response.json().then((res) => {
            setIsLoading(false);
            setIsVBA(res.vba);
            setTotalPages(res.totalPages);
            setShowApiData(true);
            const dataWithUidAndFormattedDates = res.data.map((item) => {
              if (item.Paid_Date) {
                item.Paid_Date = new Date(item.Paid_Date).toLocaleDateString("en-US");
              }
              if (item.Date_Of_Service) {
                item.Date_Of_Service = new Date(item.Date_Of_Service).toLocaleDateString("en-US");
              }
              return {
                ...item,
                uid: uuidv4(), // Adding a unique identifier to each item
              };
            });
            setApiData(dataWithUidAndFormattedDates);
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
        alert("An error has occured when searching for claims");
      })
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };


  const downloadClaims = (data: any) => {
    let dataToDownload: any[] = data.map((item: any) => {
      // Remove unwanted fields
      const { Tax_ID, Provider_Addr1, Provider_City, Provider_State, Provider_Zip, uid, ...filteredItem } = item;
      return filteredItem;
    });

    const timeStamp = new Date()
      .toISOString()
      .replace(/[^0-9]/g, "")
      .slice(0, -3);
    const csv = Papa.unparse(dataToDownload);
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvData);
    let hiddenElement = document.createElement("a");
    hiddenElement.href = csvUrl;
    hiddenElement.download = `${groupNumber}_Claims_${timeStamp}.csv`;
    hiddenElement.click();
  };

  const handleDownloadErrorsCheck = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    downloadClaims(apiData);
  };

  const goToClaims = () => {
    //navigate("/claims");
    window.location.href = "https://optimedhealth.formstack.com/forms/gcf";
  };

  const goBack = () => {
    setShowApiData(false);
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  if (showApiData) {
    return (
      <div className="App">
        <header className="app-header">
          <div className="header">
            <h1>Claims</h1>
          </div>
          <div className="button-container2">
            <button className="claims-back-button" onClick={goBack}>
              Back
            </button>

            <button className="download-claims-data" onClick={(e) => handleDownloadErrorsCheck(e)}>
              Download Claims Information
            </button>
          </div>
        </header>
        {isVBA && tk ? (
          <>
            <ClaimsDataGrid idToken={tk} data={apiData} />
          </>
        ) : (
          <>
            <ClaimsDataGrid idToken={tk} data={apiData} />
          </>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <div className="header">
          <h1>Claims Search</h1>
          <p>Enter the information below in order to do a claims search</p>
          <p>Only the group number is required, additional information allows you to specify your search</p>
        </div>

        <form onSubmit={handleSubmit}>
          {role == "admin" || role == "internal_ugp" ? (
            <label>
              Group Number:
              <input type="text" value={groupNumber} onChange={handleGroupNumberChange} placeholder="Required" />
            </label>
          ) : (
            <label>Group Number: {selectedGroup}</label>
          )}
          <div className="name-inputs">
            <label>
              First Name: (Optional)
              <input type="text" value={firstName} onChange={handleFirstNameChange} placeholder="" />
            </label>
            <label>
              Last Name: (Optional)
              <input type="text" value={lastName} onChange={handleLastNameChange} placeholder="" />
            </label>
          </div>
          <div className="date-inputs">
            <label>
              Paid Start:
              <Tooltip title="Optional">
                <input
                  type="date"
                  value={paidDateStart}
                  onChange={handlePaidDateStartChange}
                  placeholder="(Optional)"
                />
              </Tooltip>
            </label>
            <label>
              Paid Date End:
              <Tooltip title="Optional">
                <input type="date" value={paidDateEnd} onChange={handlePaidDateEndChange} placeholder="(Optional)" />
              </Tooltip>
            </label>
          </div>
          <label>
            Claims Number: (Optional)
            <input
              type="text"
              value={claim}
              onChange={handleClaimChange}
              placeholder="Please enter either your 7 or 17 digit claim number "
            />
          </label>
          {isAdmin && (
            <label>
              Member ID: (Optional)
              <input type="text" value={assignedId} onChange={handleAssignedIdChange} placeholder="" />
            </label>
          )}
          <label>
            Date of Birth:
            <Tooltip title="Optional">
              <input type="date" value={dateOfBirth} onChange={handleDateOfBirthChange} placeholder="(Optional)" />
            </Tooltip>
          </label>
          <button type="submit">Look up</button>
          <button className="blue-button" onClick={goToClaims}>
            Claims Upload
          </button>
        </form>
      </div>
    );
  }
};

export default ClaimsLookupSystem;
