import React, { useState, useRef, ChangeEvent } from "react";
import { host } from "../../config";
import ReCAPTCHA from "react-google-recaptcha";
import "./claimsUpload.css";
import CustomAlert from "components/alert/CustomAlert";
import { CircularProgress } from "@mui/material";

type UserFormData = {
  lastName: string;
  firstName: string;
  groupNumber: string;
  memberNumber: string;
  dob: string;
  email?: string;
  files?: File[];
  claimType: string;
};

const App: React.FC = () => {
  const [userFormData, setFormData] = useState<UserFormData>({
    lastName: "",
    firstName: "",
    groupNumber: "",
    memberNumber: "",
    dob: "",
    email: "",
    claimType: "",
  });
  interface Files {
    [key: string]: File;
  }

  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const [selectedSections, setSelectedSections] = useState<string[]>([]);
  const [files, setFiles] = useState<Files>({});

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const closeAlert = () => {
    setAlertOpen(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const captchaRef = useRef<ReCAPTCHA | null>(null);

  const handleSectionSelection = (section: string) => {
    setSelectedSections((prevSections) =>
      prevSections.includes(section) ? prevSections.filter((sec) => sec !== section) : [...prevSections, section]
    );
  };

  const handleFileChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : new File([], "");
    setFiles((prevFiles) => ({
      ...prevFiles,
      [name]: file,
    }));
  };

  const formFileLabels: [string, { label: string; desc: string }[]][] = [
    [
      "GAP",
      [
        {
          label: "Claim Form",
          desc: "The Claim Form can be either from your provider OR from your hospital or outpatient facility. We need this information to confirm the amounts charged to your Major Medical Plan.",
        },
        {
          label: "Copy of your Explanation of Benefits",
          desc: "This form allows us to determine the benefit amount we will pay through your GAP plan. Please note that without a copy of your explanation of benefits we will not be able to determine any reimbursement amount for your claim.",
        },
      ],
    ],
    [
      "MEC",
      [
        {
          label: "Claim Form",
          desc: "The Claim Form can be either from your provider OR from your hospital or outpatient facility.",
        },
      ],
    ],
    [
      "Limited Medical",
      [
        {
          label: "Claim Form",
          desc: "The Claim Form can be either from your provider OR from your hospital or outpatient facility.",
        },
      ],
    ],
    [
      "Additional Documents",
      [
        {
          label: "Supporting Claim & Reimbursement documentation",
          desc: "You can supply any additional documents, receipts, or other materials that allow us to properly process your claim.",
        },
      ],
    ],
  ];

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    let formData = new FormData();
    Object.entries(files).forEach(([name, file]) => {
      formData.append("files", file, `${file.name.split(".").slice(0, -1)}_${name}.${file.name.split(".").slice(-1)}`);
    });
    formData.append("body", JSON.stringify({ ...userFormData, recaptchaToken: recaptchaValue }));
    var counter = 0;
    for (let file of formData.getAll("files")) {
      counter++;
    }
    if (!recaptchaValue) {
      setAlertMessage("Please verify you are not a robot.");
      setAlertOpen(true);
      return;
    } else if (counter == 0) {
      setAlertMessage("Please upload at least one file");
      setAlertOpen(true);
    } else if (
      Number(userFormData.groupNumber) <= 200000 &&
      userFormData.groupNumber !== "04004" &&
      userFormData.groupNumber !== "" &&
      userFormData.groupNumber !== "000001"
    ) {
      setAlertMessage("This feature is not supported for your group. We apologize for the inconvience.");
      setAlertOpen(true);
      return;
    }
    setIsLoading(true);
    console.log(formData);
    console.log(`${host}/api/claims/claimUpload`);
    fetch(`${host}/api/claims/claimUpload`, {
      method: "POST",
      body: formData,
    })
      .then((response: Response) => {
        if (response.status == 200) {
          setIsLoading(false);
          setAlertMessage(
            `Your Claim has been successfully uploaded. If there are any issues our team will reach out.`
          );
          setAlertOpen(true);
        } else {
          setIsLoading(false);
          setAlertMessage(
            `We could not accept your claim. Please try again later or contact your account manager if the issue persists`
          );
          setAlertOpen(true);
        }
        setFormData({
          lastName: "",
          firstName: "",
          groupNumber: "",
          memberNumber: "",
          dob: "",
          email: "",
          claimType: "",
        });
        setFiles({});
        captchaRef.current?.reset();
        setRecaptchaValue(null);
      })
      .catch((error) => {
        setIsLoading(false);
        // alert("An error occurred: " + error.message);
        setAlertMessage("An error occurred: " + error.message);
        setAlertOpen(true);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  const onChange = (value: any) => {
    console.log("Captcha value:", value);
    setRecaptchaValue(value);
  };

  const handleMouseOver = (e: React.MouseEvent<HTMLDivElement>, desc: string) => {
    const tooltip = document.getElementById(`tooltip_${desc}`);
    if (tooltip) {
      tooltip.style.left = e.clientX + "px";
      tooltip.style.top = e.clientY + "px";
      tooltip.innerHTML = `Upload a file for ${desc}`;
      tooltip.style.visibility = "visible";
      tooltip.style.opacity = "1";
    }
  };

  const handleMouseLeave = (desc: string) => {
    const tooltip = document.getElementById(`tooltip_${desc}`);
    if (tooltip) {
      tooltip.style.visibility = "hidden";
      tooltip.style.opacity = "0";
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="claim-submission-container">
      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
      <h1 className="claim-submission-title">Claim Submission Form</h1>
      <p className="claim-submission-supporting-text">
        Please enter the following data exactly as it appears on your card.
      </p>
      <form onSubmit={handleSubmit} className="claim-submission-form">
        <label>
          Last Name
          <input
            type="text"
            placeholder="Last Name"
            className="claim-submission-input"
            value={userFormData.lastName}
            onChange={(e) => setFormData({ ...userFormData, lastName: e.target.value })}
          />
        </label>
        <label>
          First Name
          <input
            type="text"
            placeholder="First Name"
            className="claim-submission-input"
            value={userFormData.firstName}
            onChange={(e) => setFormData({ ...userFormData, firstName: e.target.value })}
          />
        </label>
        <label>
          Group Number
          <input
            type="text"
            placeholder="Group Number"
            className="claim-submission-input"
            value={userFormData.groupNumber}
            onChange={(e) => setFormData({ ...userFormData, groupNumber: e.target.value })}
          />
        </label>
        <label>
          Member Number
          <input
            type="text"
            placeholder="Member Number"
            className="claim-submission-input"
            value={userFormData.memberNumber}
            onChange={(e) => setFormData({ ...userFormData, memberNumber: e.target.value })}
          />
        </label>
        <label className="claim-submission-date-input">
          Date Of Birth
          <input
            type="date"
            className="claim-submission-date-input"
            value={userFormData.dob}
            onChange={(e) => setFormData({ ...userFormData, dob: e.target.value })}
          />
        </label>
        <label>
          Email (Optional)
          <input
            type="email"
            placeholder="Email (Optional)"
            className="claim-submission-input"
            value={userFormData.email}
            onChange={(e) => setFormData({ ...userFormData, email: e.target.value })}
          />
        </label>
        <label className="claim-submission-label">
          {" "}
          Where Did this claim occur?
          <select
            placeholder="None"
            className="claim-submission-select"
            name="claimType"
            value={userFormData.claimType}
            onChange={(e) => setFormData({ ...userFormData, claimType: e.target.value })}
          >
            <option disabled value="">
              Select...
            </option>
            <option value="PROF">Doctors Office</option>
            <option value="UB92">Hospital</option>
          </select>
        </label>
        <div className="claim-submission-captcha">
          <ReCAPTCHA
            sitekey="6Lftt0EoAAAAAHah4Wv3DZVjYiacRU7xcVqhzm9U" // Use environment variable
            onChange={onChange}
            ref={captchaRef}
          />
        </div>
        <div>
          <label>Please select the product(s) to upload your documents:</label>
          <div className="section-buttons">
            {formFileLabels.map(([section]) => (
              <button
                key={section}
                type="button"
                onClick={() => handleSectionSelection(section)}
                style={{
                  backgroundColor: selectedSections.includes(section) ? "#FF8C00" : "#004e9d",
                  color: "white",
                  margin: "5px",
                }}
              >
                {section.toUpperCase()}
              </button>
            ))}
          </div>
        </div>
        <div className="upload-components">
          {formFileLabels.map(([formTitle, fileLabels]) => {
            if (selectedSections.includes(formTitle)) {
              return (
                <div className="upload-form" key={formTitle}>
                  <h2>{formTitle}</h2>
                  {fileLabels.map(({ label, desc }) => (
                    <div className="form-field" key={label}>
                      <div className="label-info-wrapper">
                        <label>{label}</label>
                        <div
                          className="info-bubble"
                          onMouseOver={(e) => handleMouseOver(e, desc)}
                          onMouseLeave={() => handleMouseLeave(desc)}
                        >
                          i
                        </div>
                      </div>
                      <input type="file" onChange={handleFileChange(label)} />
                      <div className="tooltip-text" id={`tooltip_${desc}`}></div>
                    </div>
                  ))}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
        <button type="submit" className="claim-submission-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default App;
